<template>
  <v-container fluid class="content-wrapper" style="overflow: hidden">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body ml-1">
          <!-- Products sell and New Orders -->
          <div class="row match-height">
            <div class="col-12">
              <div class="d-flex flex-row justify-start align-center">
                <h4 class="fw-600 mb-0">
                  {{ $t("SigninPromotion.create_basic_deal") }}
                </h4>
                <v-btn
                  icon
                  class="ml-3"
                  @click="initAll()"
                  :class="loadingData && 'animate__spining'"
                  :disabled="loadingData"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
              <v-skeleton-loader v-if="loadingData" type="heading"></v-skeleton-loader>
              <h6 v-else>{{ $t("SigninPromotion.create_basic_deal_label") }}</h6>
            </div>
            <hr />

            <div v-if="loadingData" class="d-flex flex-column" style="width: 100%">
              <v-divider />
              <v-skeleton-loader
                type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
              ></v-skeleton-loader>
            </div>

            <div v-if="!loadingData" class="col-12 mt-2">
              <span class="border-bottom"></span>
            </div>

            <div v-if="!loadingData" class="col-12 mt-2">
              <h5 class="fw-600">{{ $t("SigninPromotion.create_country_detail") }}</h5>
            </div>

            <div v-if="!loadingData" class="col-md-12 p-md-3">
              <div class="content-body">
                <section id="timeline" class="timeline-left timeline-wrapper">
                  <ul class="timeline">
                    <li class="timeline-line"></li>
                    <!-- 1 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">1</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2">
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.nameofpromotion") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group validate">
                              <div class="controls">
                                <input
                                  type="text"
                                  id="promotionName"
                                  class="form-control"
                                  style="min-height: 3rem"
                                  :placeholder="`${$t(
                                    'SigninPromotion.nameofpromotion'
                                  )}`"
                                  name
                                  v-model="promotionName"
                                  @focus="getfocus('promotionName')"
                                  @change="getfocus('promotionName')"
                                  @mouseleave="mouseOver('promotionName')"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 2 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">2</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2">
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_basic_rateplan") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group scope-open">
                              <div class="controls">
                                <div
                                  class="dropdown select"
                                  @click="getfocus('rateplan')"
                                  @mouseleave="mouseOver('rateplan')"
                                >
                                  <a id="rateplan">
                                    <v-select
                                      v-model="rate_plan"
                                      :items="itemsAllRatePlanPrice"
                                      item-text="rateplan_name"
                                      item-value="_id"
                                      label
                                      :placeholder="$t('SignupStep.select_one')"
                                      :no-data-text="$t('SignupStep.nodata')"
                                      color="#1e9ff2"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                      @change="selectRatePlanFunc(rate_plan)"
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content
                                          @mouseenter="getfocus('rateplan')"
                                          @mouseleave="mouseOver('rateplan')"
                                        >
                                          <v-list-item-title
                                            v-html="data.item.rateplan_name"
                                            class="title-signup-sj"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 3 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">3</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_basic_room") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group scope-open">
                              <div class="controls">
                                <v-radio-group v-model="all_rooms" :disabled="!rate_plan">
                                  <div class="d-flex flex-row align-center justify-start">
                                    <v-radio
                                      :label="$t('SigninPromotion.create_basic_room_all')"
                                      :value="true"
                                      color="#1e9ff2"
                                      @change="checkAllRooms()"
                                    ></v-radio>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-start mt-3"
                                  >
                                    <v-radio
                                      :label="
                                        $t('SigninPromotion.create_basic_room_some')
                                      "
                                      :value="false"
                                      color="#1e9ff2"
                                      @change="checkNotAllRooms()"
                                    ></v-radio>
                                  </div>
                                </v-radio-group>
                              </div>
                              <div
                                v-if="itemsAllRooms.length && !all_rooms"
                                class="controls pl-2 scope-more"
                              >
                                <div v-for="item in itemsAllRooms" :key="item._id">
                                  <div class="custom-control custom-checkbox">
                                    <input
                                      :id="`${item._id}`"
                                      name="rooms"
                                      type="checkbox"
                                      class="custom-control-input"
                                      :value="`${item._id}`"
                                      :checked="item.check"
                                      @change="checkSomeRooms(item)"
                                      :disabled="all_rooms !== false"
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="`${item._id}`"
                                      >{{ item.value }}</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 4 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">4</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_basic_discount") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="align-self-center">
                                <input
                                  type="number"
                                  min="0"
                                  @keypress="isNumber($event)"
                                  id="discount_percent"
                                  class="form-control"
                                  :placeholder="$t('SigninPromotion.discount')"
                                  name
                                  v-model="discount_percent"
                                  @focus="getfocus('discount_percent')"
                                  @change="getfocus('discount_percent')"
                                  @mouseleave="mouseOver('discount_percent')"
                                />
                              </div>
                              <div class="mx-1">
                                <h3>%</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 5 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">5</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2">
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_basic_canbuy") }}
                          </h4>
                          <h6>
                            {{ $t("SigninPromotion.create_basic_canbuy_daterange") }}
                          </h6>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group">
                              <div class="controls">
                                <div class="row">
                                  <div class="col-xl-4 col-md-6 col-12">
                                    <v-date-picker
                                      v-model="bookingRange"
                                      color="#1e9ff2"
                                      range
                                      @change="changeBookingRangeFunc()"
                                    ></v-date-picker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="bookingRange.length > 1" class="row">
                              <div class="col-md-12">
                                <div class="alert mb-2 w-75 alert-info" role="alert">
                                  <!-- :class="
                                    warnbookingmonths
                                      ? 'alert-warning text-bold'
                                      : 'alert-info'
                                  " -->
                                  {{ $t("SigninPromotion.create_basic_discount_range") }}
                                  <br />
                                  {{ bookingRange[0] }}
                                  {{ $t("SigninPromotion.to") }}
                                  {{ bookingRange[1] }}
                                  <br />
                                </div>

                                <!-- <span class="error-text-input" v-if="warnbookingmonths">
                                  *{{ $t("SigninPromotion.minbooking") }} 3
                                  {{ $t("SigninPromotion.months") }}
                                </span> -->
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12">
                                <h5>
                                  {{ $t("SigninPromotion.create_basic_limit_canbuy") }}
                                </h5>
                              </div>
                            </div>
                            <div class="form-group scope-open">
                              <div class="controls">
                                <v-radio-group v-model="booking_value">
                                  <div class="d-flex flex-row align-center justify-start">
                                    <v-radio
                                      :label="$t('SigninWeekend.dontwant')"
                                      :value="false"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-start mt-3"
                                  >
                                    <v-radio
                                      :label="$t('SigninWeekend.want')"
                                      :value="true"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                </v-radio-group>
                              </div>
                              <div v-if="booking_value" class="controls pl-2 scope-more">
                                <label>
                                  {{
                                    $t("SigninPromotion.create_basic_limit_reserve_time")
                                  }}
                                  :
                                </label>
                                <div class="d-flex pt-1">
                                  <div
                                    class="dropdown select"
                                    style="width: 13rem"
                                    @click="getfocus('starttime')"
                                    @mouseleave="mouseOver('starttime')"
                                  >
                                    <a id="starttime">
                                      <v-select
                                        v-model="booking.start_time"
                                        :items="itemsReserveStartTime"
                                        label
                                        :placeholder="$t('SignupStep.select_one')"
                                        :no-data-text="$t('SignupStep.nodata')"
                                        color="#1e9ff2"
                                        solo
                                        flat
                                        dense
                                        single-line
                                        hide-details
                                        :disabled="!booking_value"
                                      >
                                        <template v-slot:item="data">
                                          <v-list-item-content
                                            @mouseenter="getfocus('starttime')"
                                            @mouseleave="mouseOver('starttime')"
                                          >
                                            <v-list-item-title
                                              v-html="data.item"
                                              class="title-signup-sj"
                                            ></v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-select>
                                    </a>
                                  </div>
                                  <div class="mx-3 align-self-center">
                                    {{ $t("SigninPromotion.to") }}
                                  </div>
                                  <div
                                    class="dropdown select"
                                    style="width: 13rem"
                                    @click="getfocus('endtime')"
                                    @mouseleave="mouseOver('endtime')"
                                  >
                                    <a id="endtime">
                                      <v-select
                                        v-model="booking.end_time"
                                        :items="itemsReserveEndTime"
                                        label
                                        :placeholder="$t('SignupStep.select_one')"
                                        :no-data-text="$t('SignupStep.nodata')"
                                        color="#1e9ff2"
                                        solo
                                        flat
                                        dense
                                        single-line
                                        hide-details
                                        :disabled="!booking_value"
                                      >
                                        <template v-slot:item="data">
                                          <v-list-item-content
                                            @mouseenter="getfocus('endtime')"
                                            @mouseleave="mouseOver('endtime')"
                                          >
                                            <v-list-item-title
                                              v-html="data.item"
                                              class="title-signup-sj"
                                            ></v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </v-select>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 6 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">6</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.title_stay") }}
                          </h4>
                          <h6>{{ $t("SigninPromotion.create_basic_stay") }}</h6>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group scope-open">
                              <div class="controls">
                                <v-radio-group v-model="stay_value">
                                  <div class="d-flex flex-row align-center justify-start">
                                    <v-radio
                                      :label="
                                        $t('SigninPromotion.create_basic_voucher_expire')
                                      "
                                      :value="false"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-start mt-3"
                                  >
                                    <v-radio
                                      :label="
                                        $t(
                                          'SigninPromotion.create_basic_select_daterange'
                                        )
                                      "
                                      :value="true"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                </v-radio-group>
                              </div>
                              <div v-if="stay_value" class="controls pl-2 scope-more">
                                <div class="row">
                                  <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                                    {{ $t("SigninPromotion.create_basic_start_date") }}
                                    :
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                    <input
                                      type="date"
                                      id="choice_startdate"
                                      class="form-control"
                                      name="choice_startdate"
                                      data-placement="top"
                                      data-title="Date"
                                      data-original-title
                                      title
                                      style="min-height: 3rem; width: 15rem"
                                      :disabled="!stay_value"
                                      v-model="stay.start_date"
                                      :min="bookingRange[0]"
                                      :max="bookingRange[1]"
                                      @change="changeStayRangeFunc()"
                                    />
                                  </div>
                                </div>
                                <div class="row pt-1">
                                  <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                                    {{ $t("SigninPromotion.create_basic_end_date") }}
                                    :
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                                    <input
                                      type="date"
                                      id="choice_enddate"
                                      class="form-control"
                                      name="choice_enddate"
                                      data-placement="top"
                                      data-title="Date"
                                      data-original-title
                                      title
                                      style="min-height: 3rem; width: 15rem"
                                      :disabled="!stay_value"
                                      v-model="stay.end_date"
                                      :min="checkMinBookingFunc()"
                                      @change="changeStayRangeFunc()"
                                    />
                                  </div>
                                </div>

                                <div
                                  v-if="stay.start_date || stay.end_date"
                                  class="row pt-1 d-flex flex-column"
                                >
                                  <div
                                    class="alert ml-2 mb-2 w-75"
                                    :class="
                                      warnstaymonths
                                        ? 'alert-warning text-bold'
                                        : 'alert-info'
                                    "
                                    role="alert"
                                  >
                                    {{
                                      $t("SigninPromotion.create_basic_discount_range")
                                    }}
                                    <br />
                                    {{ stay.start_date }}
                                    {{ $t("SigninPromotion.to") }}
                                    {{ stay.end_date }}
                                    <br />
                                  </div>

                                  <span class="error-text-input" v-if="warnstaymonths">
                                    *{{ $t("RateAndInventory.rest_less") }} 3
                                    {{ $t("SigninPromotion.months") }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <!-- 7 -->
                    <li class="timeline-item">
                      <div class="timeline-badge">
                        <span
                          class="bg-red bg-lighten-1 d-flex align-center justify-center"
                          data-toggle="tooltip"
                          data-placement="right"
                          title
                          data-original-title
                        >
                          <b class="text-white">7</b>
                        </span>
                      </div>
                      <div class="timeline-card card border-grey border-lighten-2" style>
                        <div class="card-header">
                          <h4 class="card-title">
                            {{ $t("SigninPromotion.create_basic_discount_voucher") }}
                          </h4>
                        </div>
                        <div class="card-content">
                          <div class="card-body">
                            <div class="form-group scope-open">
                              <div class="controls">
                                <v-radio-group v-model="limit_value">
                                  <div class="d-flex flex-row align-center justify-start">
                                    <v-radio
                                      :label="$t('SigninWeekend.dontwant')"
                                      :value="false"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                  <div
                                    class="d-flex flex-row align-center justify-start mt-3"
                                  >
                                    <v-radio
                                      :label="$t('SigninWeekend.want')"
                                      :value="true"
                                      color="#1e9ff2"
                                    ></v-radio>
                                  </div>
                                </v-radio-group>
                              </div>
                              <div v-if="limit_value" class="controls pl-2 scope-more">
                                <div class="d-flex pt-1">
                                  <div class="align-self-center">
                                    {{ $t("SigninPromotion.create_basic_amount") }}
                                  </div>
                                  <div class="mx-3 align-self-center">
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      id="limit"
                                      class="form-control"
                                      placeholder="30"
                                      name
                                      v-model="limit_number"
                                      @focus="getfocus('limit')"
                                      @change="getfocus('limit')"
                                      @mouseleave="mouseOver('limit')"
                                    />
                                  </div>
                                  <div class="align-self-center">
                                    {{ $t("SigninPromotion.create_basic_unit") }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            </div>

            <div v-if="!loadingData" class="col-md-12 py-3 mb-5">
              <div class="row">
                <div class="col-md-12">
                  <v-btn
                    outlined
                    class="mr-3 btn-warning"
                    style="min-height: 2.625rem; min-width: 7.5rem"
                    dark
                    @click="gobackFunc()"
                    >{{ $t("SigninRoomDetails.btn_back") }}</v-btn
                  >
                  <v-btn
                    outlined
                    class="btn-info"
                    style="min-height: 2.625rem; min-width: 7.5rem"
                    dark
                    @click="savePromotionFunc()"
                    >{{ $t("SigninRoomDetails.btn_save") }}</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninPromotionTypeBasicScreen",
  metaInfo: {
    title: "Promotion",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    promotionTypeID: "",
    promotionID: "",
    value_element: "",
    propertyData: null,
    itemsCountry: [],
    showMonthCurrent: "",
    showMonthNext: "",
    promotionName: "",
    rate_plan: "",
    itemsAllRatePlanPrice: [],
    itemsAllRooms: [],
    all_rooms: null,
    select_rooom_value: null,
    select_rooom: "",
    discount_percent: null,
    stay_value: false,
    stay: {
      start_date: null,
      end_date: null,
      limit_day: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
    },
    default_age_of_voucher: {
      expire_date: null,
      length: null,
    },
    bookingRange: [],
    booking_value: false,
    itemsReserveStartTime: ["08:00", "09:00", "10:00", "11:00", "12:00", "13:00"],
    booking: {
      start_date: "2021-12-01",
      end_date: "2021-12-15",
      start_time: "",
      end_time: "",
    },
    itemsReserveEndTime: ["23:00", "00:00", "01:00"],
    limit_value: false,
    limit_number: 0,
    oldPromotionData: {},
    oldPromotionUpdate: false,
    warnbookingmonths: false,
    warnstaymonths: false,
    loadingData: false,
    promotionDetail: {},
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.promotionTypeID = self.$route.params.promotionTypeId;
    self.promotionID = self.$route.params.promotionId;
    self.initAll();
  },
  watch: {
    all_rooms() {
      const self = this;
      if (self.all_rooms) self.checkAllRooms();
    },
    booking() {
      const self = this;
      var tempBookings = [];
      if (self?.booking?.length > 1) tempBookings = self.booking;
      // console.log(self.booking);
      // console.log(new Date(self.booking[0]), new Date(self.booking[1]));
      // const temp = tempBookings.sort(function (a, b) {
      //   return new Date(b) - new Date(a);
      // });
      // console.log(temp);
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gobackFunc() {
      this.loading = true;
      // if (window.history?.length > 2) {
      //   EventBus.$emit("goBackPage");
      // } else {
      const link = {
        name: "SigninPromotionScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      // }
    },
    initAll() {
      const self = this;
      self.initPropertyData();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var haveData = false;
      self.loadingData = true;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        temp?.promotion?.map((el) => {
          if (el._id == self.promotionID) {
            self.oldPromotionData = el;
            // console.log("old: ", el);
            self.promotionDetail = el;
            haveData = true;
            self.promotionName = el?.promotion_name || null;
            self.rate_plan = el?.rate_plan || null;
            self.discount_percent = el?.discount_percent || null;
            if (el?.stay?.start_date && el?.stay?.end_date) self.stay_value = true;
            else self.stay_value = false;
            self.stay.start_date = el?.stay?.start_date
              ? moment(el?.stay?.start_date).format("YYYY-MM-DD")
              : null;
            self.stay.end_date = el?.stay?.end_date
              ? moment(el?.stay?.end_date).format("YYYY-MM-DD")
              : null;
            self.stay.limit_day = el?.stay?.limit_day || [
              "sunday",
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
            ];
            if (el?.booking?.start_time && el?.booking?.end_time)
              self.booking_value = true;
            else self.booking_value = false;
            self.bookingRange =
              el?.booking?.start_date && el?.booking?.end_date
                ? [
                    moment(el?.booking?.start_date).format("YYYY-MM-DD"),
                    moment(el?.booking?.end_date).format("YYYY-MM-DD"),
                  ]
                : [];
            self.booking.start_time = el?.booking?.start_time || null;
            self.booking.end_time = el?.booking?.end_time || null;
            if (el?.limit_number) self.limit_value = true;
            else self.limit_value = false;
            self.limit_number = el?.limit_number || null;
            self.changeBookingRangeFunc();
          }
        });
        if (!haveData) {
          self.showMonthCurrent = moment().format("YYYY-MM-DD");
          self.showMonthNext = moment().add(1, "M").format("YYYY-MM-DD");
        }
        self.loadingData = false;
        self.initRatePlanData();
      }
    },
    async initRatePlanData() {
      const self = this;
      var tempRatePlanPrice = [];
      var tempOldSelect = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          const hasRoomValue = (currentValue) => currentValue?.room?.value;
          res?.data?.result?.map((el) => {
            if (
              (el?.name || el?.rate_plan?.value) &&
              el?.rooms?.length &&
              el?.rooms.every(hasRoomValue)
            ) {
              // console.log(el._id == self.rate_plan);
              if (el._id == self.rate_plan) tempOldSelect = el?._id;
              tempRatePlanPrice.push({
                ...el,
                rateplan_id: el?._id,
                rateplan_name: el?.name || el?.rate_plan?.value,
              });
            }
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsAllRatePlanPrice = tempRatePlanPrice;
        // console.log("rate paln: ", self.itemsAllRatePlanPrice);
        if (self.promotionID) {
          if (tempOldSelect) self.selectRatePlanFunc(tempOldSelect);
        }
      }
    },
    selectRatePlanFunc(item) {
      const self = this;
      var tempOldRoom = [];
      var tempRoom = [];
      const temp = self?.itemsAllRatePlanPrice?.find((p) => p._id == item);
      if (self.rate_plan) {
        self.all_rooms = null;
        self.select_rooom_value = null;
        self.select_rooom = "";
        temp?.rooms?.map((el) => {
          tempRoom.push({
            _id: el?.room?._id || el?._id,
            value: el?.room?.value,
            check: false,
          });
        });
      } else {
        self.all_rooms = null;
        self.select_rooom_value = null;
        self.select_rooom = "";
        self.itemsAllRooms = [];
      }
      self.itemsAllRooms = tempRoom;
      if (self.promotionID && item) {
        var tempCheck = [];
        temp?.rooms?.map((el) => {
          // console.log("rooms: ", el);
          const checkRoom = self.oldPromotionData?.rooms?.find(
            (p) => p == el._id || p == el.room._id
          );
          if (checkRoom) {
            tempOldRoom.push({
              _id: el?.room?._id || el?._id,
              value: el?.room?.value,
              check: true,
            });
            tempCheck.push(el);
          } else {
            tempOldRoom.push({
              _id: el?.room?._id || el?._id,
              value: el?.room?.value,
              check: false,
            });
          }
        });
        self.itemsAllRooms = tempOldRoom;
        if (tempCheck?.length == tempOldRoom?.length) self.all_rooms = true;
        else self.all_rooms = false;
        // console.log("old: ", self.oldPromotionData);
      }
    },
    checkAllRooms() {
      const self = this;
      if (self.all_rooms) self.itemsAllRooms.map((el) => (el.check = true));
      else self.itemsAllRooms.map((el) => (el.check = false));
    },
    checkNotAllRooms() {
      const self = this;
      if (!self.promotionID) self.itemsAllRooms.map((el) => (el.check = false));
    },
    checkSomeRooms(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    changeBookingRangeFunc() {
      const self = this;
      self.bookingRange.sort();
      var countMonths =
        moment(self.bookingRange[1]).diff(moment(self.bookingRange[0]), "months", true) ||
        0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnbookingmonths = false;
      else self.warnbookingmonths = true;
    },
    changeStayRangeFunc() {
      const self = this;
      if (self.stay.start_date && !self.bookingRange[0] && !self.bookingRange[1])
        self.stay.end_date = moment(self.stay.start_date)
          .add(3, "month")
          .format("YYYY-MM-DD");
      var countMonths =
        moment(self.stay.end_date).diff(moment(self.stay.start_date), "months", true) ||
        0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnstaymonths = false;
      else self.warnstaymonths = true;
    },
    checkMinBookingFunc() {
      const self = this;
      var tmpDate = null;
      tmpDate = self.stay.start_date
        ? moment(self.stay.start_date).add(3, "month").format("YYYY-MM-DD")
        : self.bookingRange[0];
      var countMonths =
        moment(self.stay.end_date).diff(moment(self.stay.start_date), "months", true) ||
        0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnstaymonths = false;
      else self.warnstaymonths = true;

      return tmpDate;
    },
    checkMaxBookingFunc() {
      const self = this;
      var tmpDate = null;
      tmpDate = self.bookingRange[1]
        ? moment(self.bookingRange[1]).add(3, "month").format("YYYY-MM-DD")
        : self.bookingRange[0]
        ? moment(self.bookingRange[0]).add(3, "month").format("YYYY-MM-DD")
        : self.bookingRange[0];
      var countMonths =
        moment(self.stay.end_date).diff(moment(self.stay.start_date), "months", true) ||
        0;
      if (Math.round(parseFloat(countMonths)) >= 3) self.warnstaymonths = false;
      else self.warnstaymonths = true;

      return tmpDate;
    },
    async savePromotionFunc() {
      const self = this;
      var tempRooms = [];
      self?.itemsAllRooms?.map((el) => {
        if (el.check) tempRooms.push(el._id);
      });

      var countMonths =
        moment(self.bookingRange[1]).diff(moment(self.bookingRange[0]), "months", true) ||
        0;

      const obj = {
        promotion_name: self.promotionName,
        rate_plan: self.rate_plan,
        rooms: tempRooms,
        discount_percent: Number(self.discount_percent),
        stay: {
          start_date: self.stay.start_date || null,
          end_date: self.stay.end_date || null,
          limit_day: self.stay.limit_day,
        },
        booking: {
          start_date: self.bookingRange[0] || null,
          end_date: self.bookingRange[1] || null,
          start_time: self.booking.start_time || null,
          end_time: self.booking.end_time || null,
        },
        limit_number: self.limit_value ? (self.limit_number || null) : null,
        limit_country: null,
        inactive_dates: null,
        minimum_stay: null,
        promotion: self.promotionTypeID,
        status:
          self.promotionDetail.status == "expired"
            ? "active"
            : self.promotionDetail.status,
      };

      // console.log("obj: ", obj);

      if (!obj?.promotion_name) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")} ${self
            .$t("SigninPromotion.nameofpromotion")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
        // } else if (!self?.bookingRange?.length) {
        //   swal(
        //     self.$t("Alert.warn_title"),
        //     `${self.$t("Alert.pleaseselect")} ${self
        //       .$t("SigninPromotion.create_basic_canbuy")
        //       .toLowerCase()}`,
        //     self.$t("Alert.warn_label"),
        //     {
        //       button: false,
        //       timer: 3000,
        //     }
        //   );
      } else if (!obj?.rooms?.length) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t("SignupStep.step2").toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.discount_percent) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")} ${self
            .$t("SigninPromotion.discount")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.promotion) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self
            .$t("SigninPromotion.promotions")
            .toLowerCase()}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
        // } else if (Math.round(parseFloat(countMonths)) < 3) {
        //   swal(
        //     self.$t("Alert.warn_title"),
        //     `${self.$t("SigninPromotion.minbooking")} 3 ${self.$t(
        //       "SigninPromotion.months"
        //     )}`,
        //     self.$t("Alert.warn_label"),
        //     {
        //       button: false,
        //       timer: 3000,
        //     }
        //   );
      } else if (
        obj?.promotion_name &&
        // self?.bookingRange?.length &&
        obj?.rooms?.length &&
        obj?.discount_percent &&
        obj?.promotion
        // && Math.round(parseFloat(countMonths)) >= 3
      ) {
        EventBus.$emit("loadingtillend");
        var res = null;
        try {
          if (self.promotionID) {
            res = await self.axios.put(
              process.env.VUE_APP_API +
                `/property/promotion?promotion_id=${
                  self?.promotionID || self?.$route?.params?.promotionId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          } else {
            res = await self.axios.post(
              process.env.VUE_APP_API +
                `/property/promotion?property_id=${
                  self?.propertyID || self?.$route?.params?.propertyId
                }`,
              obj,
              {
                headers: {
                  Authorization: self?.userToken,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          }
          if (res.status == 200) {
            EventBus.$emit("loadingtillend");
            self.oldPromotionUpdate = true;
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          // setTimeout(() => {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          ).then(() => self.gobackFunc());
          // }, 5000);
        }
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    checkChangeData() {
      const self = this;
      var cantgonextroute = true;
      var tempOldRooms = [];
      self?.oldPromotionData?.rooms?.map((el) => {
        tempOldRooms.push(el);
      });

      var tempRooms = [];
      self?.itemsAllRooms?.map((el) => {
        if (el.check) tempRooms.push(el?.room?._id || el?._id);
      });
      tempRooms.sort();
      tempOldRooms.sort();

      var isSameRooms =
        tempOldRooms?.length == tempRooms?.length &&
        tempOldRooms?.every(function (element, index) {
          return element === tempRooms[index];
        });

      if (self.promotionID) {
        if (self.oldPromotionUpdate) {
          cantgonextroute = false;
        } else if (
          self.promotionName !== self.oldPromotionData.promotion_name ||
          self.rate_plan !== self.oldPromotionData.rate_plan ||
          !isSameRooms ||
          self.discount_percent !== self.oldPromotionData.discount_percent ||
          self.bookingRange[0] !==
            moment(self.oldPromotionData.booking.start_date).format("YYYY-MM-DD") ||
          self.bookingRange[1] !==
            moment(self.oldPromotionData.booking.end_date).format("YYYY-MM-DD") ||
          Number(self.limit_number) !== Number(self.oldPromotionData.limit_number)
        ) {
          cantgonextroute = true;
        } else if (
          self.oldPromotionData.booking.start_time &&
          self.oldPromotionData.booking.end_time
        ) {
          if (
            self.booking.start_time !== self.oldPromotionData.booking.start_time ||
            self.booking.end_time !== self.oldPromotionData.booking.end_time
          ) {
            cantgonextroute = true;
          } else cantgonextroute = false;
        } else if (
          self.oldPromotionData.stay.start_date &&
          self.oldPromotionData.stay.end_date
        ) {
          if (
            self.stay.start_date !==
              moment(self.oldPromotionData.stay.start_date).format("YYYY-MM-DD") ||
            self.stay.end_date !==
              moment(self.oldPromotionData.stay.end_date).format("YYYY-MM-DD")
          ) {
            cantgonextroute = true;
          } else cantgonextroute = false;
        } else cantgonextroute = false;
      } else {
        if (self.oldPromotionUpdate) {
          cantgonextroute = false;
        } else if (
          self?.promotionName ||
          self?.bookingRange?.length ||
          tempRooms?.length ||
          self?.discount_percent
        ) {
          cantgonextroute = true;
        } else cantgonextroute = false;
      }
      return cantgonextroute;
    },
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
@import "../../../styles/signin/timeline.css";
@import "../../../styles/signin/promotion.css";

.v-expansion-panel-header {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
